import axios from "axios";
import qs from "qs";
import { createApp } from 'vue';

let common = {
    urlBase: "/api/",
    //urlBase:"https://www.codessp.cn/api/",
    bus: createApp(),
    get: function (url, data) {
        try {
            url = this.urlBase + url;
            axios.defaults.withCredentials = false;
            return axios({
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "get",
                url: url,
                params: data
            })
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    post: function (url, data) {
        url = this.urlBase + url;
        let t;
        axios.defaults.withCredentials = false;
        try {
            t = axios({
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "post",
                url: url,
                data: qs.stringify(data),
            })
        } catch (e) {
            console.log(e);
            throw e;
        }
        return t;

    },

    postWithConfig: function (url, formData, config) {
        return axios
            .post(this.urlBase + url, formData, config)
    },

    //如果all为空，那么只取一级目录，如果all为sub，则取所有目录，并且折叠二三级目录；如果all为nowrap则不折叠二三级目录
    getChapters2(courseId, chapterId, setData, all) {
        if (all == undefined)
            all = "";
        this.post("chapters", { courseId: courseId, chapterId: chapterId, all: all }).then((response) => {
            //console.log(response.data.data);
            if (response.status == "200") {
                // return response.data.data;
                setData(response.data.data)
            }
            //return null;
        });
    },

    //如果all为空，那么只取一级目录，如果all为sub，则取所有目录，并且折叠二三级目录；如果all为nowrap则不折叠二三级目录
    getChapters(courseId, setData, all) {
        console.log("common.getChapters");
        console.log(courseId);
        this.getChapters2(courseId, "", setData, all)
    },


    //如果all为空，那么只取一级目录，如果all为sub，则取所有目录，并且折叠二三级目录；如果all为nowrap则不折叠二三级目录
    getChaptersById(chapterId, setData, all) {
        if (all == undefined)
            all = "";
        this.post("chaptersById", { chapterId: chapterId, all: all }).then((response) => {
            //console.log(response.data.data);
            if (response.status == "200") {
                // return response.data.data;
                setData(response.data.data)
            }
            //return null;
        });
    },

    setUser: function (user) {
        if (user) {
            sessionStorage.setItem("user", JSON.stringify(user));
        }
    },

    getUser: function () {
        let isLogin = sessionStorage.getItem("isLogin");
        //console.log(this.user);
        if (isLogin) {
            let t = sessionStorage.getItem("user");
            console.log(t);
            //console.log(this.user.username + "," + this.user.role)
            return JSON.parse(t);
        }
        else {
            let t = this.getCookie("user");
            t = decodeURIComponent(t);
            //console.log(t);
            if (t) {
                return JSON.parse(t);
            }
            return null;
        }
    },

    updateCourse: function (courseId, username) {
        this.post("updateCourse", { courseId: courseId, username: username }).then((response) => {
            if (response.data.code == 200) {
                this.setCourseToSession(courseId);
            }
        });
    },

    setCourseToSession: function (courseId) {
        sessionStorage.setItem("courseId", courseId);
    },

    getCourseIdFromSession: function () {
        let t = sessionStorage.getItem("courseId");
        //console.log(t)
        if (t)
            return t;
        else
            return "1";
    },

    getCourseId: function (username, setData) {
        this.get("courseId", { username: username }).then((response) => {
            //console.log(response.data.data);
            if (response.status == "200") {
                // return response.data.data;
                setData(response.data.data.courseId);
            }

        });

    },

    chapterNumber: function (code) {
        if (!code)
            return "";
        let firstLevelCode = code.substring(0, 2);
        let secondLevelCode = code.substring(2, 4);
        let thirdLevelCode = code.substring(4, 6);

        let m1 = parseInt(firstLevelCode);
        let m2 = parseInt(secondLevelCode);
        let m3 = parseInt(thirdLevelCode);
        if (m2 == 0 && m3 == 0)
            return "第" + m1 + "章 ";
        if (m3 == 0) {
            return m1 + "." + m2 + "  ";
        }
        return m1 + "." + m2 + "." + m3 + "  ";
    },

    getChapterStudies: function (username, courseId, chapterId, setData) {
        this.post("chapterStudy",
            {
                username: username,
                courseId: courseId,
                chapterId: chapterId
            }).then((response) => {
                //console.log(response.data);
                if (response.status == "200") {
                    // return response.data.data;
                    setData(response.data);
                }
                //return null;
            });
    },

    blankAnswer: function (username, id, value, inputId, setData) {
        if (username == "null" || username == "") {
            if (confirm("未登录无法练习，需要登录吗？")) {
                window.location = "login.jsp";
            }
            return;
        }

        value = value.trim();
        if (value == "") {
            return;
        }

        this
            .post("chapterQuizStudySaving", {
                username: username,
                quizId: id,
                inputId: inputId,
                answer: value,
            })
            .then((response) => {
                let data = response.data.data;
                if (data) {
                    data = JSON.parse(data);
                    //this.comment = data.comment;
                    //this.stat = data.stat;
                    setData(data);
                    //console.log(this);

                    // $("#" + id).html(format($("#" + id).text()));
                    //$(":button").removeAttr("disabled");
                    // $(":input").removeAttr("disabled");
                    //$("#comment" + id).empty(); // 清掉批注，忘掉错对

                    // let respondText = "";
                    // if (commemt) respondText = codeFormator.reverseXMLFormat(commemt);

                    //   $("#comment" + id).html(data.comment);
                    //   console.log(id + "," + $("#comment" + id).html());

                    //   if (data.comment != "") {
                    //     $("#comment" + id).removeClass("correct");
                    //   } else {
                    //     $("#comment" + id).addClass("correct");
                    //   }
                }
            }).catch(err => {
                console.log("error");
                console.log(err);
            });
    },

    getFile: function (event, files, filesShow) {
        let file = event.target.files;
        for (let i = 0; i < file.length; i++) {
            //    上传类型判断
            let imgName = file[i].name;
            let idx = imgName.lastIndexOf(".");
            if (idx != -1) {
                let ext = imgName.substr(idx + 1).toUpperCase();
                ext = ext.toLowerCase();
                files.push(file[i]);
                // if (ext != "pdf" && ext != "doc" && ext != "docx") {
                // } else {

                // }
            } else {
                console.log(imgName);
            }

            if (filesShow) {
                let reader = new FileReader()
                reader.readAsDataURL(file[i])
                reader.onload = function () {
                    filesShow.push(reader.result);
                }
            }
        }

        return Promise.resolve(file);
    },

    getClasses(valid, setClasses) {
        //valid是all就查找所有班级，true查找可用班级,false查找所有不可用班级
        this.get("class", { valid: valid }).then((response) => {
            //console.log(JSON.parse(response.data.data.classes));
            setClasses(JSON.parse(response.data.data.classes));
        })
    },

    correct(chapterId, classId) {
        this.post("batchCorrect", { chapterId: chapterId, classId: classId }).then((response => {
            console.log(response.data.info);
        }));
    },

    getRoomNames(appName, setRoomNames) {
        this.get("roomNames", { appName: appName }).then((response) => {
            console.log(response.data.data);
            setRoomNames(response.data.data);
        })
    },

    resetScore(courseId, classId) {
        this.post("recomputeScore", { courseId: courseId, classId: classId });
    },

    resetUserScore(courseId, username) {
        this.post("recomputeUserScore", { courseId: courseId, username: username });

    },
    correction(code, courseId, username, setData) {
        this.post("ChapterQuizBatchCorrecting", {
            courseId: courseId,
            code: code,
            username: username
        }).then((response) => {
            if (response) {
                //$("#message").html(data.message);
                setData(response.data.info);
            }
        });
    },
    getChapterStudyScore(code, courseId, username, setData) {
        this.get("getChapterStudyScore", {
            courseId: courseId,
            code: code,
            username: username
        }).then((response) => {
            if (response) {
                //$("#message").html(data.message);
                setData(response.data.info);
            }
        });
    },
    //获取cookie、
    getCookie(name) {
        let reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        let arr = document.cookie.match(reg);
        if (arr)
            return (arr[2]);
        else
            return null;
    },

    //设置cookie,增加到vue实例方便全局调用
    setCookie(c_name, value, expiredays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + expiredays);
        document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
    },

    //删除cookie
    delCookie(name) {
        var exp = new Date();
        exp.setTime(exp.getTime() - 1);
        var cval = this.getCookie(name);
        if (cval != null)
            document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
    },

    setLoginStatus(user, courseId) {
        sessionStorage.setItem("isLogin", "true");
        //console.log(res.data.data);
        //sessionStorage.setItem("courseId", res.data.data.course);
        sessionStorage.setItem("user", JSON.stringify(user));
        sessionStorage.setItem("courseId", courseId);

        //common.setCookie("user",JSON.stringify(user),60 * 60 * 24 * 30);

        //console.log(JSON.stringify(res.data.data.course))
        //this.$emit("login", "true");
        //this.lastUrl = sessionStorage.getItem("lastUrl");


    },

    exportToExcel: function (obj, json) {
        obj.table2excel(json);
        // $("#roomsData").table2excel({
        //   exclude: ".noExl",
        //   sheetName: "卡位统计",
        //   filename: "卡位统计",
        //   exclude_img: true,
        //   exclude_links: true,
        //   exclude_inputs: true,
        // });
    },

    getTime(localTime) {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }

        let day = date.getDate();
        if (day < 10) {
            day = "0" + day;
        }
        let splitor = " ";
        if (localTime) {
            splitor = "T";
        }
        let hour = date.getHours();
        let minute = date.getMinutes();
        let second = date.getSeconds();
        let time = year
            + '-' + month + '-' + day + splitor + hour + ':' + minute + ':' + second;
        return time;
    },

    splitCodeResult(rst) {
        //console.log("================"+rst+"================")
        if (!rst) {
            return "";
        }
        let t = rst.split("@");
        let r = "";
        t.forEach(a => {
            r = r + a + "<br/>";
        });
        return r;
    }

    // /**
    //  * 执行代码并把结果返回到sel中console变量
    //  * @param {*} codeDivId 包含代码的元素id 
    //  * @param {*} sel 页面对象
    //  * @returns 
    //  */
    // executeCode(codeDivId,sel){
    //     let plainCode = $("#"+codeDivId).text();
    //     console.log(plainCode)
    //     let codeLang = "python";
    //     if (sel.courseId == 1) {
    //       codeLang = "java";
    //     }

    //     let code = $("#"+codeDivId).html();
    //     plainCode = $("#"+codeDivId).text()

    //     if (codeLang == "python") {
    //       //python处理转行,先测试有内容
    //       if (plainCode) {
    //         code = code.replace(/<br>/g, "\n");
    //         code = code.replaceAll("</div>", "\n</div>");
    //         if (code.indexOf("<code>") >= 0 || code.indexOf("<pre>") >= 0 || code.indexOf("codeNewLine") >= 0)
    //           code = $(code).text();
    //       } else {
    //         return;
    //       }

    //     } else {
    //       //如果不是python代码
    //       code = plainCode;
    //     }

    //     if (plainCode.match(/[\s]*input[\s]*/g)) {
    //       sel.console = "不支持输入语句";
    //       return;
    //     }

    //     common.post("exec", { code: code, codeLang: codeLang }).then(((res) => {
    //       console.log(res)
    //       if (res.data.data) {
    //         sel.console = res.data.data;
    //       }
    //     }))
    //   },

}
export default common;