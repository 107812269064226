<template>
  <router-view />
</template>

<script>

export default {
  name: "App",
  
};
</script>
<style>
#app {
  font-family:"Courier New", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  /*
  color: #2c3e50;*/
}

body {
  line-height: 1.75em;
  background: #f3f3f3;
}

#main {
  position: relative;
  padding: 0;
  color: #5e5e5e;
  margin: 0 auto;
  clear: both;
  height: 92vh;
}

#sidebar{
  height: 92vh;
}

#content{
  height: 92vh;
}

div.footer{
  height: 8vh;
}

div.header{
  height: 5vh;
  min-height: 53px;
}



#main h2,
#main h3,
#main h4 {
  color: #242424;
}

#content {
  overflow: auto;
  /*margin: 0 auto;min-width: 1000px;*/
  padding: 40px;
  float: right;
  background: #fff;

}

/* #content {
  margin: 0 0 0 0;
  min-width: 1000px;
  padding: 40px;
  float: left;
} */

#content select{
  text-align: left;
}

.content1 {
  padding: 20px 40px 0px 40px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 2px 8px #ccc;
  border-left: 5px solid #ccc;
  border-right: 5px solid #ccc;
  float: none;
}

.imageList {
  list-style: none;
  padding-left: 0;
}

.imageList li {
  clear: both;
}

#banner {
  background: #fff;
}

#box1 {
  /*min-width: 600px;*/
  overflow: hidden;
  margin: 0 0 35px 0;
  width: 100%;
}

#box2 {
  /*min-width: 600px;*/
  overflow: hidden;
  box-shadow: 5px;
  width: 100%;
}

#box3 {
  /*min-width: 300px;*/
  overflow: hidden;
  margin: 0 0 0 330px;
}

#chapterTitle {
  display: block;
  /*width: 600px;text-align: center;*/
  margin: 10px auto 10px auto;
  font-weight: bold;
  font-family: "华文仿宋";
}

#chapterContent img {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 1em auto;
  box-shadow: 0 2px 8px #000;
  display: block;
  max-width: 100%;
  height: auto;
}

#chapterContent a {
  color: dodgerblue;
}

#main {
  top: 53px;
  position: relative;
  padding: 0;
  /*min-width: 900px;
	max-width: 1180px;*/
  color: #5e5e5e;
}

#main strong {
  color: #3e3e3e;
}

#main ul {
  list-style: none;
}

#main ul li {
  padding: 15px 0 15px 0;
}

#main ul li.first {
  padding-top: 0;
  border-top: 0;
}

#main ul.imageList li {
  padding: 15px;
  font-size: 16px;
}

#sidebar {
  /*width: 240px;*/
  z-index: 0;
  left: 0;
  border-right: 5px solid #95142a;
  overflow: auto;
}

#sidebar h3 {
  /*width: 150px;*/
  margin: 0 auto 20px auto;
  font-weight: bold;
}

#sidebar a {
  text-decoration: none;
}

#sidebar h2,
#sidebar h3,
#sidebar h4 {
  color: #fff;
}

#sidebar ul {
  list-style: none;
}

#sidebar ul li {
  border-top: dotted 1px #70665f;
  padding-bottom: 0;
  padding-top: 15px;
  padding-left: 15px;
  margin-bottom: 15px;
}

#sidebar ul li.first {
  padding-top: 0;
  border-top: 0;
}

#sidebar ul li ul {
  list-style: none;
  margin-left: 20px;
  display: none;
  padding: 0;
  margin-bottom: 0;
}

#sidebar ul li ul li {
  border: none;
  margin-top: 0px;
  margin-bottom: 0px;
}

progress {
  height: 15px;
}

.correct,
.error {
  display: block;
  height: 20px;
  width: 20px;
  background: url("assets/state.svg") no-repeat 0 -20px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  float: left;
}

.correct {
  background-position: 0 0;
}

.footer {
  position: relative;
}

#childNodesOrQuizes .comment span {
  color: #f00;
  font-size: 15px;
}

#childNodesOrQuizes button {
  text-decoration: none;
  margin-left: 20px;
}

#childNodesOrQuizesTitle {
  background: linear-gradient(to right, #d09600, #fff);
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  height: 40px;
  font-family: "微软雅黑";
}

.chapterBref {
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
}

.chapterIntro {
  font-size: 14px;
  font-weight: normal;
}

.quizTitle {
  font-size: 18px;
  font-weight: bold;
}

.sectionContent {
  font-size: 20px;
  font-weight: bold;
  font-family: "微软雅黑";
}

iframe {
  height: 500px;
}

#main .courseKeyword {
  text-decoration: none;
  color: #1495ff;
}

#main .courseKeyword:before {
  content: "";
}

.keyInfo {
  position: fixed;
  left: 5px;
  top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  font-family: "Courier New";
  width: 270px;
  padding: 20px;
  display: block;
  z-index: 10;
  background: #fff;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

#chapterContent div {
  text-indent: 2em;
}

#chapterContent a:before {
  content: "\e144 ";
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

#chapterContent a {
  text-decoration: none;
}

#content #line {
  position: relative;
  float: left;
  min-height: 400px;
  height: auto;
  width: 40px;
  font-family: "Courier New";
  text-align: center;
  vertical-align: middle;
  padding: 20px 0 20px 0;
  line-height: 20px;
  font-size: 16px;
  border-style: solid;
  border-color: #000;
  border-width: 2px;
  border-right-color: #fff;
  border-right-width: 1px;
  background: #c7edcc;
}

#content #collapseBranket {
  position: relative;
  float: left;
  height: 400px;
}

#codeEditor {
  min-width: 750px;
  counter-reset: section;
  width: 100%;
  min-height: 50px;
  height: auto;
  font-family: "Courier New";
  color: #000;
  background: #fff;
  /*#C7EDCC;*/
  line-height: 20px;
  font-size: 16px;
  overflow: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 8px #000;
  border: 2px solid #fff;
  border-radius: 5px;
  padding: 5px 0;
  background: #f6f6f6;
}

.codeEditor,
.htmlEditor {
  /*min-width: 750px; */
  counter-reset: section;
  width: 95%;
  min-height: 20px;
  min-width: 500px;
  height: auto;
  font-family: "Courier New";
  color: #000;
  background: #fff;
  /*#C7EDCC;*/
  line-height: 20px;
  font-size: 16px;
  overflow: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  /*box-shadow: 0 2px 8px #000;
	border: 2px solid #fff;
	border-radius: 5px;*/
  padding: 5px 0;
  background: #f6f6f6;
  overflow: auto;
}

code {
  font-family: "Courier New";
  font-size: 16px;
  color: #000;
  padding: 0;
}

.field {
  color: #0000c0;
}

.annotations {
  color: #646464;
}

.plain {
  color: #000;
}

.keyword {
  color: #7f0055;
  font-weight: bold;
}

.pyFunction {
  color: #460f74;
  font-weight: bold;
}

.string {
  color: #2a00ff;
}

.css{
  color:#ff57bf;
}

.csskeyword{
  font-weight: bold;
  color: #7F007F;
}

.colon ~ span.css {
  color: #2a00ff;
  font-style:italic;
}

.comments,
.CStylecomments {
  color: #3f7f5f;
}

.CStylecomments:before {
  content: " * ";
}

.javaDoc,
.javaDocComments {
  color: rgb(63, 95, 191);
}

.javaDocComments:before {
  content: " * ";
}

.tag {
  color: #646464;
}

.jspTag {
  color: #bf5f3f;
}

.htmlTag {
  color: #3f7f7f;
}

.htmlAttr,
.htmlattr {
  color: rgb(127, 0, 127);
}

.codeNewLine:before {
  counter-increment: section;
  content: counter(section) ".";
  color: #ccc;

  padding-left: 00;
  width: 4em;

  padding-right: 2em;

  display: inline-block;
  text-indent: 0;
}

.codeNewLine:hover:before {
  color: #666;
}

.codeNewLine:hover {
  background: #eee;
}

.token.comment {
  color: rgba(0, 0, 0, 0.3);
}

.token.keyword,
.content .token.selector,
.content .token.tag {
  color: #b457ff;
}

.content .token.keyword,
.content .token.selector,
.content .token.tag {
  color: #b457ff;
}

.content .token.punctuation {
  color: #222;
}

.content .token.attr-name,
.content .token.operator,
.content .token.property {
  color: #ff4d00;
}

.content .token.attr-value {
  color: #222;
}

#formDiv {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}</style>
